@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// workaround for <SelectColumnsButton/> dialog
.MuiPopover-paper {
  .MuiBox-root {
    div {
      label {
        display: flex;
        align-items: center;
      }
    }
  }
}

// bugfix for MUI select that has size=small
.MuiFormControl-root.MuiFormControl-small {
  .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
    transform: translate(14px, 9px)
  }
}

.recharts-wrapper {

  .recharts-tooltip-wrapper {
    outline: 0;
  }
}
