.popper {
  z-index: 10 !important;
  :global {
    & .react-datepicker {
      .react-datepicker__day, .react-datepicker__day-name {
        margin: 0.25rem;
        width: 2rem;
        line-height: 2rem;
      }
      font-family: inherit;
      min-width: max-content;
      box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
      & .react-datepicker__month-container {
        & .react-datepicker__header {
          margin-left: -0.5rem;
          margin-right: -0.5rem;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
      .react-datepicker__triangle {
        display: none;
      }
      .react-datepicker__header--custom, .react-datepicker__header--time, .react-datepicker__time-container {
        background-color: white;
        border-color: rgba(174, 174, 174, 0.6);
      }
      .react-datepicker__month-container {
        padding: 0 0.5rem;

        .react-datepicker__monthPicker {
          .react-datepicker__month-text {
            font-size: 15px;

            &.react-datepicker__month-text--keyboard-selected {
              border-radius: 5px;
            }
          }
        }
      }
      .react-datepicker__day--today, .react-datepicker__day--today:hover {
        border: none;
        background-color: inherit;
        box-sizing: content-box;
        font-weight: normal;
        border-radius: 0.3rem;
      }
      .react-datepicker__day--today:hover {
        background-color: #f0f0f0;
      }
      .react-datepicker__time-container ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        box-sizing: content-box;
      }
      & .react-datepicker__time-list .react-datepicker__time-list-item--selected,
      & .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover {
        background-color: #f68220 !important;
      }

      .react-datepicker__day-name {
        color: #7b7b7b;
      }
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--keyboard-selected:hover,
      .react-datepicker__day--selected,
      .react-datepicker__day--selected:hover {
        background-color: #f68220;
        border-radius: 50%;
      }

      .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range {
        color: #f68220;
        background-color: rgba(0, 0, 0, 0.2);
      }
      .react-datepicker__day--in-range.react-datepicker__day--in-selecting-range {
        background-color: #f68220 !important;
        color: white;
      }
    }
  }
}

.wrapper {
  width: 100%;
}
